import { z } from 'zod'
import type { ZodType, ZodTypeAny } from 'zod'

class ValidationRule<T> {
    constructor(
        public _defaultValue: T,
        public _schema: ZodType,
    ) {}

    public _errorMap: z.ZodErrorMap

    public errorMessage(errorMessage: string, errorType?: z.ZodIssueOptionalMessage): ValidationRule<T> {
        this._errorMap = (error, ctx) => ({
            message: !errorType
                ? errorMessage
                : ((({
                    [errorType as any]: errorMessage,
                }) as Partial<Record<typeof error.code, string>>)[error.code] || ctx.defaultError),
        })
        return this
    }
}

const field = <T>(defaultValue: T, schema?: ZodTypeAny) => new ValidationRule<T>(defaultValue, schema)

type SchemaFromType<T> = {
    [K in keyof T]: ValidationRule<T[K]>
}

export const useValidation = () => {
    const hasBeenValidatedOnce = ref(false)

    const createValidation = <T>(schema: SchemaFromType<T>) => {
        type ErrorValue = string | false
        type SchemaValueTypes = { [K in keyof SchemaFromType<T>]: SchemaFromType<T>[K] extends ValidationRule<infer V> ? V : never }
        type SchemaErrorTypes = { [K in keyof T]: ErrorValue }

        const reactiveValues = reactive<SchemaValueTypes>({} as any)
        const reactiveErrors = reactive<SchemaErrorTypes>({} as any)
        const validationRuleMapping = new Map<string, ValidationRule<any>>()

        for (const [key, validationRule] of Object.entries(schema)) {
            reactiveValues[key] = (validationRule as ValidationRule<any>)._defaultValue
            reactiveErrors[key] = false
            validationRuleMapping.set(key, validationRule as ValidationRule<any>)
        }

        const validate = (options?: { ignore?: string[] }) => {
            if (!hasBeenValidatedOnce.value) {
                hasBeenValidatedOnce.value = true
            }
            for (const [key, validationRule] of validationRuleMapping) {
                if (!validationRule._schema) {
                    continue
                }
                const ignoreValidation = options?.ignore?.filter(Boolean).includes(key)
                const validation = validationRule._schema.safeParse(
                    reactiveValues[key],
                    { errorMap: validationRule._errorMap },
                )
                if (validation.success === true || ignoreValidation) {
                    reactiveErrors[key] = false
                }
                else {
                    reactiveErrors[key] = validation.error.issues[0].message
                }
            }
        }

        watch([reactiveValues], () => hasBeenValidatedOnce.value && validate())

        const isValid = computed(() => {
            return Object.values(reactiveErrors).every(error => error === false)
        })

        return {
            values: reactiveValues,
            errors: reactiveErrors,
            validate,
            isValid,
        }
    }

    return {
        createValidation,
        field,
        z,
    }
}
